import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { handleGoogleSignOut } from "../services/AuthService";
import "../menu.css"

const Menu = () => {
  const { isAuthenticated, userName, userRole } = useAuth();
  const admin = process.env.REACT_APP_ADMINROLE;

  return (
    <div className="menu">
      <div className="header-text">Welkom {userName}</div>
      <ul>
        {isAuthenticated && userRole && (
          <li className="link">
            <Link to="/data">Gegevens</Link>
          </li>
        )}
        {isAuthenticated && userRole === admin && (
          <li className="link">
            <Link to="/accounts">Admin</Link>
          </li>
        )}
        <li className="link">
          {isAuthenticated ? (
            <Link onClick={handleGoogleSignOut}>Uitloggen</Link>
          ) : (
            <Link to="/">Inloggen</Link>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Menu;
