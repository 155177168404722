import React, { useState } from "react";
import Swal from "sweetalert2";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../config/firebaseConfig";

const Add = ({ users, setUsers, setIsAdding, getUsers }) => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  const handleAdd = async (e) => {
    e.preventDefault();

    if (!firstname || !lastname || !email || !role) {
      return Swal.fire({
        icon: "warning",
        title: "Fout!",
        text: "Alle velden zijn verplicht.",
        showConfirmButton: true,
      });
    }

    const newUser = {
      firstname,
      lastname,
      email,
      role,
    };

    try {
      await setDoc(doc(db, "users", email), newUser);
    } catch (error) {
      console.log(error);
    }

    setUsers(users);
    setIsAdding(false);
    getUsers();

    Swal.fire({
      icon: "success",
      title: "Toegevoegd",
      text: `${firstname + " " + lastname}  is toegevoegd.`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <>
      <br></br>
      <br></br>
      <form className="order-form" onSubmit={handleAdd}>
        <label htmlFor="firstName">Voornaam</label>
        <input
          id="voornaam"
          type="text"
          name="voornaam"
          value={firstname}
          autoComplete="off"
          onChange={(e) => setFirstName(e.target.value)}
        />
        <div></div>
        <label htmlFor="lastName">Achternaam</label>
        <input
          id="achternaam"
          type="text"
          name="achternaam"
          value={lastname}
          autoComplete="off"
          onChange={(e) => setLastName(e.target.value)}
        />
        <div></div>
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="text"
          name="email"
          value={email}
          autoComplete="off"
          onChange={(e) => setEmail(e.target.value)}
        />
        <div></div>
        <label htmlFor="role">Rol</label>
        <select
          id="role"
          type="text"
          name="role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
        >
          <option value="">Selecteer rol</option>
          <option value="User">User</option>
          <option value="Admin">Admin</option>
        </select>
        <div></div>
        <div></div>
        <div>
          <button className="formbutton" type="submit" value="Add">
            Opslaan
          </button>
          <button
            className="formbutton"
            type="button"
            value="Cancel"
            onClick={() => setIsAdding(false)}
          >
            Annuleren
          </button>
        </div>
      </form>
    </>
  );
};

export default Add;
