// shipmentUtils.js

import { createPrivateSdk, FetchClient } from "@myparcel/sdk";
import { PostShipments } from "@myparcel/sdk";
import {
  createPackage,
  createPackageCB,
  createPackageSP,
  createNoInsurancePackage,
  createMailboxPackage,
  createDigitalStamp,
} from "./myParcel";

const MY_BASE_64_ENCODED_API_KEY = process.env.REACT_APP_MYPARCEL_TOKEN;

export const clientConfig = {
  headers: {
    Authorization: "bearer " + MY_BASE_64_ENCODED_API_KEY,
  },
};

export const sdk = createPrivateSdk(new FetchClient(clientConfig), [
  new PostShipments(),
]);

export const countryCodes = {
  NL: "Nederland",
  BE: "België",
  D: "Duitsland",
};

export const renderCountryLabel = (country) => {
  return country === "D" ? "DE" : country; 
};

// Handle product change
export const handleProductChange = (selectedProduct, label_description) => {
  switch (selectedProduct) {
    case "Startpakket":
      return createPackage(label_description);
    case "Chromebook":
      return createPackageCB(label_description);
    case "Telefoon":
      return createPackageSP(label_description);
    case "Simkaart":
      return createDigitalStamp(label_description);
    case "OTP Token":
      return createDigitalStamp(label_description);
    case "Oplader Telefoon":
      return createDigitalStamp(label_description);
    case "Oplader Chromebook":
      return createNoInsurancePackage(label_description);
    case "Bumper 1 Stuks":
      return createDigitalStamp(label_description);
    case "Bumpers":
      return createNoInsurancePackage(label_description);
    case "Chromebook sleeve":
      return createMailboxPackage(label_description);
    default:
      return null;
  }
};

export const createShipment = async ({
  label_description,
  country_label,
  postcode,
  city,
  address,
  address2,
  address3,
  name,
  privatemail,
  worknumber,
  options,
  product,
}) => {
  // Gewicht per producttype
  const weightMapping = {
    "Simkaart": 50,
    "Oplader Telefoon": 350,
    "OTP Token": 20,
    "Bumper 1 Stuks": 50,
  };

  // Sanitize nullable values
  const sanitizedAddress3 = address3 || "";
  const sanitizedWorkNumber = worknumber || "";

  // Bouw de shipment payload
  const shipment = {
    reference_identifier: label_description,
    recipient: {
      cc: country_label,
      postal_code: postcode,
      city: city,
      street: address,
      number: address2,
      number_suffix: sanitizedAddress3,
      person: name,
      email: privatemail,
      phone: sanitizedWorkNumber,
    },
    carrier: 1,
    options,
    ...(weightMapping[product]
      ? { physical_properties: { weight: weightMapping[product] } }
      : {}),
  };

//   console.log("Shipment payload:", shipment);

  // Voer de API-aanroep uit
  try {
    const result = await sdk.postShipments({ body: [shipment] });
    console.log("Shipment created:", result);
    return result; // Retourneer het resultaat
  } catch (error) {
    console.error(
      "Error creating shipment:",
      error.response?.data || error.message
    );
    throw error;
  }
};
