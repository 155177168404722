//MyParcel package types 

export const createPackage = (reference_identifier) => ({
  package_type: 1,
  only_recipient: 1,
  signature: 1,
  return: 0,
  insurance: {
    amount: 50000,
    currency: "EUR",
  },
  label_description: reference_identifier,
});

export const createPackageCB = (reference_identifier) => ({
  package_type: 1,
  only_recipient: 1,
  signature: 1,
  return: 0,
  insurance: {
    amount: 50000,
    currency: "EUR",
  },
  label_description: reference_identifier,
});

export const createPackageSP = (reference_identifier) => ({
  package_type: 1,
  only_recipient: 1,
  signature: 1,
  return: 0,
  insurance: {
    amount: 25000,
    currency: "EUR",
  },
  label_description: reference_identifier,
});

export const createNoInsurancePackage = (reference_identifier) => ({
  package_type: 1,
  only_recipient: 1,
  signature: 0,
  return: 0,
  label_description: reference_identifier,
});

export const createMailboxPackage = (reference_identifier) => ({
  package_type: 2,
  label_description: reference_identifier,
});

export const createDigitalStamp = (reference_identifier) => ({
  package_type: 4,
  label_description: reference_identifier,
});
